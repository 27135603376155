import { get, post } from '~/services/network/utils/axios'

export function getNewNonsingles({query, cancelSignal}) {
  return getNonsingles({
    query: {
      in_stock: true,
      new: true,
      presale: false,
      ...query
    },
    cancelSignal
  })
}

export function getPresaleNonsingles({query, cancelSignal}) {
  return getNonsingles({
    query: {
      presale: true,
      ...query
    },
    cancelSignal
  })
}

export function getMonthSaleNonsingles({query, cancelSignal}) {
  return getNonsingles({
    query: {
      discounted: true,
      featured_discount: true,
      ...query
    },
    cancelSignal
  })
}

export function getNonsingles ({query, cancelSignal}) {
  return get({
    url: 'najada2/catalog/nonsingles/',
    query,
    cancelSignal
  })
}

export function getNonsingle ({id, cancelSignal}) {
  return get({
    url: 'najada2/catalog/nonsingles/{id}/',
    id,
    cancelSignal
  })
}

export function getMtgSingles ({query, cancelSignal}) {
  return get({
    url: 'najada2/catalog/mtg-singles/',
    query,
    cancelSignal
  })
}

export function getSingles ({query, cancelSignal}) {
  return get({
    url: 'najada2/catalog/singles/',
    query,
    cancelSignal
  })
}

export function getRecommendedNonsingles ({id, cancelSignal}) {
  return get({
    url: 'najada2/catalog/nonsingles/{id}/recommended/',
    id,
    cancelSignal
  })
}

export function setNonsingleRating ({id, data}) {
  return post({
    url: 'najada2/catalog/nonsingles/{id}/rating/',
    id,
    data
  })
}

export function getRelatedNonsingles ({id, cancelSignal}) {
  return get({
    url: 'najada2/catalog/nonsingles/{id}/related/',
    id,
    cancelSignal
  })
}

export function getHomepageTitles ({query, cancelSignal}) {
  return get({
    url: '/najada2/homepage-titles/',
    query,
    cancelSignal
  })
}

export function getProductExpansions ({id, query, cancelSignal}) {
  return get({
    url: '/najada2/catalog/expansions/{id}/',
    id,
    query,
    cancelSignal
  })
}

export function getExpansions ({query, cancelSignal}) {
  return get({
    url: '/najada2/catalog/expansions/',
    query,
    cancelSignal
  })
}

export function getMtgArtists () {
  return get({
    url: 'najada2/catalog/mtg/artists/'
  })
}

export function getMtgSubtypes () {
  return get({
    url: 'najada2/catalog/mtg/subtypes/'
  })
}

export function getGames ({query, cancelSignal}) {
  return get({
    url: 'najada2/catalog/games/',
    query,
    cancelSignal
  })
}

/**
 *
 * @param {{}} data
 * @param {File?} data.card_file
 * @param {string?} data.card_data
 * @returns {Promise<AxiosResponse<*>>}
 */
export function parseBulkBuylist(data) {
  return post({
    url: '/najada2/catalog/bulk-buylist-parser/',
    multipart: true,
    data
  })
}

/**
 *
 * @param {{}} data
 * @param {File?} data.card_file
 * @param {string?} data.card_data
 * @returns {Promise<AxiosResponse<*>>}
 */
export function parseBulkPurchaselist(data) {
  return post({
    url: '/najada2/catalog/bulk-purchaselist-parser/',
    multipart: true,
    data
  })
}


/**
 *
 * @param {{}} query
 * @param {string} query.q - Searched term
 * @param {string} query.t - Searched section
 * @param cancelSignal
 * @return {Promise<AxiosResponse<any>>}
 */
export function getSearchPreviewItems ({query, cancelSignal}) {
  return get({
    url: 'najada2/catalog/search/',
    query,
    cancelSignal
  })
}
