import formatDF from 'date-fns/format'
import isPastDF from 'date-fns/isPast'
import isTomorrowDF from 'date-fns/isTomorrow'
import isTodayDF from 'date-fns/isToday'
import endOfYearDF from 'date-fns/endOfYear'
import startOfYearDF from 'date-fns/startOfYear'
import endOfMonthDF from 'date-fns/endOfMonth'
import startOfMonthDF  from 'date-fns/startOfMonth'
import differenceInDays  from 'date-fns/differenceInDays'
import getDaysInMonth  from 'date-fns/getDaysInMonth'
import getDay  from 'date-fns/getDay'
import isWithinIntervalDF  from 'date-fns/isWithinInterval'
import addMonthsDF  from 'date-fns/addMonths'
import addWeeksDF  from 'date-fns/addWeeks'
import subDaysDF  from 'date-fns/subDays'
import addDaysDF  from 'date-fns/addDays'

import cs from 'date-fns/locale/cs'
import enUS from 'date-fns/locale/en-US'

const localeMap = {
  cs,
  en: enUS
}
let defaultLocale = 'cs'

export function daysDifference(dateLeft, dateRight) {
  return differenceInDays(dateLeft, dateRight)
}

export function formatDate (date, stringFormat, locale = defaultLocale) {
  return formatDF(date, stringFormat, {
    locale: localeMap[locale] || localeMap[defaultLocale]
  })
}

/**
 * @param {string} dateString
 * @returns {boolean}
 */
export function isTodayOrPast (dateString = (new Date()).toISOString()) {
  const date = new Date(dateString)
  return isPastDF(date) || isToday(date)
}

/**
 * @param {string} dateString
 * @returns {boolean}
 */
export function isPast (dateString = (new Date()).toISOString()) {
  const date = new Date(dateString)
  return isPastDF(date)
}

/**
 * @param dateString
 * @returns {Date | *}
 */
export function startOfMonth (dateString = (new Date()).toISOString()) {
  return startOfMonthDF(new Date(dateString))
}

/**
 * @param {string} dateString
 * @return {Date | *}
 */
export function endOfMonth (dateString = (new Date()).toISOString()) {
  return endOfMonthDF(new Date(dateString))
}

/**
 * @param {string} dateString
 * @returns {Date | *}
 */
export function startOfYear (dateString = (new Date()).toISOString()) {
  return startOfYearDF(new Date(dateString))
}

/**
 * @param {string} dateString
 * @return {Date | *}
 */
export function endOfYear (dateString = (new Date()).toISOString()) {
  return endOfYearDF(new Date(dateString))
}

/**
 *
 * @param {string} dateString
 * @param {string} locale
 * @return {string | *}
 */
export function dayMonthYearISO (dateString = (new Date()).toISOString(), locale = defaultLocale) {
  return formatDate(new Date(dateString), 'y-MM-dd', locale)
}

/**
 *
 * @param {string} dateString
 * @param {string} locale
 * @returns {string}
 */
export function dayMonthYearTimeISO (dateString = (new Date()).toISOString(), locale = defaultLocale) {
  return formatDate(new Date(dateString), 'y-MM-d_HH-mm-ss', locale)
}

/**
 *
 * @param {string} dateString
 * @param {string} locale
 * @returns {string}
 */
export function dayMonthYear (dateString = (new Date()).toISOString(), locale = defaultLocale) {
  return formatDate(new Date(dateString), 'd. M. y', locale)
}

/**
 *
 * @param {string} dateString
 * @param {string} locale
 * @returns {string}
 */
export function dayMonthYearTime (dateString = (new Date()).toISOString(), locale = defaultLocale) {
  return formatDate(new Date(dateString), 'd. M. y H:mm', locale)
}

/**
 *
 * @param {string} dateString
 * @param {string} locale
 * @returns {string} 21.srpna 2021
 */
export function dayMonthYearHuman (dateString = (new Date()).toISOString(), locale = defaultLocale) {
  return formatDate(new Date(dateString), 'do MMMM y', locale)
}

/**
 *
 * @param {string} dateString
 * @param {string} locale
 * @returns {string}
 */
export function dayWordDayMonthYear (dateString = (new Date()).toISOString(), locale = defaultLocale) {
  const stringFormatParts = ['iiii', 'd. M. y']
  const separator = locale === 'en' ? ', ' : ' '
  return formatDate(new Date(dateString), stringFormatParts.join(separator), locale)
}

/**
 *
 * @param {string} dateString
 * @param {string} locale
 * @returns {string}
 */
export function dayMonth (dateString = (new Date()).toISOString(), locale = defaultLocale) {
  return formatDate(new Date(dateString), 'd. M.', locale)
}

export function dayMonthNoSpace (dateString = (new Date()).toISOString(), locale = defaultLocale) {
  return formatDate(new Date(dateString), 'd.M.', locale)
}

/**
 *
 * @param {string} dateString
 * @param {string} locale
 * @returns {string}
 */
export function dayShortcut (dateString = (new Date()).toISOString(), locale = defaultLocale) {
  return formatDate(new Date(dateString), 'cccccc', locale)
}

export function monthFull (dateString = (new Date()).toISOString(), locale = defaultLocale) {
  return formatDate(new Date(dateString), 'LLLL', locale)
}

/**
 *
 * @param {string} dateString
 * @param {string} locale
 * @returns {string}
 */
export function hourMinutes (dateString = (new Date()).toISOString(), locale = defaultLocale) {
  return formatDate(new Date(dateString), 'H:mm', locale)
}

export function isToday (dateString = (new Date()).toISOString(), locale = defaultLocale) {
  return isTodayDF(new Date(dateString), locale)
}

export function isTomorrow (dateString = (new Date()).toISOString(), locale = defaultLocale) {
  return isTomorrowDF(new Date(dateString), locale)
}

export function setDefaultLocale(locale) {
  defaultLocale = locale
}

export function getNumberOfDaysInMonth(date = new Date()) {
  return getDaysInMonth(date)
}

export function getWeekDay(date = new Date()) {
  return getDay(date)
}

export function addMonths(date = new Date(), months = 1) {
  return addMonthsDF(date, months)
}

export function addWeeks(date = new Date(), weeks = 1) {
  return addWeeksDF(date, weeks)
}

export function addDays(date = new Date(), days = 1) {
  return addDaysDF(date, days)
}

export function isWithinInterval(date = new Date(), from = new Date(), to = new Date()) {
  return isWithinIntervalDF(date, {start: from, end: to})
}

export function subDays(date = new Date(), days = 1) {
  return subDaysDF(date, days)
}
