import filter from 'lodash/filter'
import find from 'lodash/find'
import flatten from 'lodash/flatten'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import NavigationPage from '~/database/models/NavigationPage'
import {langMap} from '~/services/Helpers'


/**
 *
 * @param {{}[]} pages
 * @param {string} locale
 * @returns {RouteConfig[]}
 */
export function generateRoutes (pages, locale = 'cs') {
  const lang = langMap.get(locale)
  const baseNameProperty = 'full_url'
  const nameProperty = lang === 'en' ? baseNameProperty : `${baseNameProperty}_${lang}`

  return map(pages, pageData => {
    const page = new NavigationPage(pageData)
    let url = page[nameProperty]

    if(isEmpty(url)) {
      url = page[baseNameProperty]
    }

    return {
      name: localizeDynamicRouteName(page.id, locale),
      path: url,
      meta: {
        layout: 'main',
        sidebar: 'dynamic',
        requiresAuth: false,
        page: page.id
      },
      props: (route) => {
        return {
          category: page.categoryId,
          expansion: page.expansion,
          game: page.game,
          tags: page.tags,
          o: page.extraQueryParams.o,
          ...page.extraQueryParams
        }
      }
    }
  })
}

/**
 *
 * @param {{}} config
 * @param {string} config.name
 * @param {{string: string}} config.paths
 * @param {function} config.component
 * @param {{string: any}} config.meta
 * @param {function} config.props
 * @param {{string: string}} config.redirects
 * @returns {[{path: string, component: function, meta: {string: any}, name: string}]}
 */
export function multilanguageRoute(config) {
  return map(config.paths || [], (path, locale) => {
    return {
      path,
      ...(config.name && {name: localizeRouteName(config.name, locale)}),
      component: config.component,
      meta: {
        ...(config.meta || {})
      },
      ...(config.props && {props: config.props}),
      ...(config.redirects && locale in config.redirects && {redirect: config.redirects[locale]}),
      children: filter(flatten(map(config.children || [], multilanguageRoute)), route => route.name.includes(`__${locale}`))
    }
  })
}

/**
 *
 * @param {{}[]} pages
 * @param {number} id
 * @param {string} locale
 * @param {string} name
 * @returns {{redirect: {name: string}, path, name: string}}
 */
export function generateRedirectForPage(pages, id, locale, name) {
  const page = find(pages, page => page.id === id)
  const lang = langMap.get(locale)
  const baseNameProperty = 'full_url'
  const nameProperty = lang === 'en' ? baseNameProperty : `${baseNameProperty}_${lang}`

  return {
    name: localizeDynamicRouteName(id, locale),
    path: page[nameProperty],
    redirect: {name: localizeRouteName(name, locale)}
  }
}

/**
 *
 * @param {string} name
 * @param {string} locale
 * @returns {string}
 */
export function localizeRouteName(name, locale) {
  return `${name}__${locale}`
}

/**
 *
 * @param {number} id
 * @param {string} locale
 * @returns {string}
 */
export function localizeDynamicRouteName(id, locale) {
  return localizeRouteName(`dynamic-${id}`, locale)
}
