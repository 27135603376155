import { get, post, serverInstance } from '~/services/network/utils/axios'

export function getNavigationLinks() {
  return get({
    url: 'najada2/navigation-pages/'
  })
}

export function getNavigationPageBestSellers({id, query, cancelSignal}) {
  return get({
    url: 'najada2/navigation-pages/{id}/best-seller/',
    id,
    query,
    cancelSignal,
    config: {
      headers: {
        NoAuth: true
      }
    }
  })
}

export function getNavigationPageFilter({id}) {
  return get({
    axios: serverInstance,
    url: 'api/navigation-page-filters/{id}/',
    id
  })
}

/**
 *
 * @param {{}} query
 * @param {string} query.slug
 * @returns {Promise<AxiosResponse<*>>}
 */
export function getNavigationPage({query}) {
  return get({
    axios: serverInstance,
    url: 'api/navigation-pages/',
    query
  })
}

export function getAppMenu({query}) {
  return get({
    axios: serverInstance,
    url: 'api/menu/',
    query
  })
}

export function getMetaMockData({id, query}) {
  return get({
    axios: serverInstance,
    url: 'api/meta/{id}/',
    id,
    query
  })
}

export function getCatalogMockData({id, query}) {
  return get({
    axios: serverInstance,
    url: 'api/catalog/{id}/',
    id,
    query
  })
}

export function getCurrentStatusMessage() {
  return get({
    url: 'najada2/status-message/current/',
    config: {
      headers: {
        NoAuth: true
      }
    }
  })
}

export function createRedirectRecord({data}) {
  return post({
    url: 'najada2/url-redirect/',
    data
  })
}

export function getInformationalShippingMethods ({query, cancelSignal}) {
  return get({
    url: '/shipping/shipping-method-list/',
    query,
    cancelSignal
  })
}
