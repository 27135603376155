import axios from 'axios'
import axiosRetry from 'axios-retry'
import { responseDataInterceptor, responseFetchResultInterceptor } from '~/services/network/utils/interceptors.axios'
import ApiError from '~/structures/ApiError'

let forwardedFor = null
let token = null
let unauthorizedAction = () => {}
let tooManyRequestsAction = () => {}

axios.defaults.withCredentials = true
const fallbackProductionEndpoint = 'https://wizardshop.cz/api/v1/'
const fallbackProductionServerEndpoint = 'https://najada.games/'

export const baseUrl = process.env.APP_API_ENDPOINT ? process.env.APP_API_ENDPOINT : fallbackProductionEndpoint
export const serverBaseUrl = process.env.APP_API_URL_BROWSER ? process.env.APP_API_URL_BROWSER : fallbackProductionServerEndpoint

/// Deletes proxy envs in gitlab environment causing network errors during build an slowing it down
delete process.env.http_proxy;
delete process.env.HTTP_PROXY;
delete process.env.https_proxy;
delete process.env.HTTPS_PROXY;

export const instance = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json'
  },
  proxy: false,
  withCredentials: true,
  transformRequest (data, headers) {
    if(forwardedFor) {
      headers['x-forwarded-for'] = forwardedFor
    }

    if (token && !('NoAuth' in headers)) {
      let fullToken = `Token ${token}`

      if(token.includes('Bearer')) {
        fullToken = token
      }

      headers.Authorization = fullToken
    } else if('NoAuth' in headers){
      delete headers.NoAuth
    }

    // If is JSON content type, stringify. Otherwise (e.g. multipart data), just push it forward
    return data && headers['Content-Type'].includes('json') ? JSON.stringify(data) : data
  }
})

export const serverInstance = axios.create({
  baseURL: serverBaseUrl,
  headers: {
    'Content-Type': 'application/json'
  },
  proxy: false,
  withCredentials: true
})

axiosRetry(instance, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 500;
  }
})
axiosRetry(serverInstance, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 500;
  }
})


addResponseInterceptor(instance, response => response, error => {
  if (error && 'response' in error && error.response && 'status' in error.response) {
    const config = error.response.config
    const url = error.response.config?.url

    if (config && url) {
      const status = error.response.status
      if(status === 401 && !url.includes('logout')) {
        unauthorizedAction()
      } else if (status === 429) {
        tooManyRequestsAction(error.response?.data)
      }
    }
  }

  return Promise.reject(error)
})
addResponseInterceptor(instance, responseDataInterceptor, error => Promise.reject(error))
addResponseInterceptor(instance, responseFetchResultInterceptor, error => Promise.reject(error))
addResponseInterceptor(serverInstance, responseDataInterceptor, error => Promise.reject(error))
addResponseInterceptor(serverInstance, responseFetchResultInterceptor, error => Promise.reject(error))

function hasId (id) {
  return typeof id !== 'undefined' && id !== null
}

export function setForwardedFor(ip) {
  forwardedFor = ip
}

/**
 * @param {string} newToken
 */
export function setToken (newToken) {
  token = newToken
}

export function destroyToken () {
  token = null
}

export function setBaseUrl (url) {
  instance.defaults.baseURL = url
}
export function setServerBaseUrl (url) {
  serverInstance.defaults.baseURL = url
}

/**
 *
 * @param {AxiosInstance} axios
 * @param url
 * @param id
 * @param query
 * @param cancelSignal
 * @param config
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function get ({axios = instance, url, id, query = {}, cancelSignal = null, config = {}}) {
  const internalConfig = {
    ...config,
    params: { ...query },
    ...(cancelSignal && { signal: cancelSignal })
  }
  let parsedUrl = url

  if (hasId(id)) {
    parsedUrl = parsedUrl.replace('{id}', id)
  }

  try {
    return await axios.get(parsedUrl, internalConfig)
  } catch (e) {
    throw new ApiError(e, parsedUrl)
  }
}


/**
 *
 * @param {{}} params
 * @param {string} params.url
 * @param {string|number} params.id
 * @param {{}} params.data
 * @param {boolean} params.multipart
 * @param {string} params.cancelSignal
 * @return {Promise<AxiosResponse<any>>}
 */
export async function post({url, id, data, multipart = false, cancelSignal = null}) {
  const config = {
    ...(cancelSignal && { signal: cancelSignal })
  }
  let parsedUrl = url
  let transferData = data

  if(hasId(id)) {
    parsedUrl = parsedUrl.replace('{id}', id)
  }

  if(multipart) {
    config.headers = {
      'Content-Type': 'multipart/form-data'
    }

    transferData = new FormData()
    const keys = Object.keys(data)

    for (const key of keys) {
      transferData.append(key, data[key])
    }
  }

  try{
    return await instance.post(parsedUrl, transferData, config)
  } catch (e) {
    throw new ApiError(e, parsedUrl)
  }
}

/**
 *
 * @param {{}} params
 * @param {string} params.url
 * @param {string|number} params.id
 * @param {{}} params.data
 * @return {Promise<AxiosResponse<any>>}
 */
export async function patch({url, id, data}) {
  let parsedUrl = url

  if(hasId(id)) {
    parsedUrl = parsedUrl.replace('{id}', id)
  }

  try {
    return await instance.patch(parsedUrl, data)
  } catch (e) {
    throw new ApiError(e, parsedUrl)
  }
}

export async function remove ({url, id}) {
  let parsedUrl = url

  if(hasId(id)) {
    parsedUrl = parsedUrl.replace('{id}', id)
  }

  try {
    return await instance.delete(parsedUrl)
  } catch (e) {
    throw new ApiError(e, parsedUrl)
  }
}

/**
 *
 * @param {function} method
 */
export function setUnauthorizedAction (method) {
  unauthorizedAction = method
}

/**
 *
 * @param {function} method
 */
export function setTooManyRequestsAction (method) {
  tooManyRequestsAction = method
}

/**
 * Adds interceptor for request flow
 *
 * @param {AxiosInstance} axios
 * @param {function({})} request
 * @param {function({})} error
 */
export function addRequestInterceptor(axios, request, error) {
  axios.interceptors.request.use(request, error)
}

/**
 * Adds interceptor for response flow
 *
 * @param {AxiosInstance} axios
 * @param {function({})} response
 * @param {function({})} error
 */
export function addResponseInterceptor(axios, response, error) {
  axios.interceptors.response.use(response, error)
}

/**
 * Runs promise inside try/catch block for network worker tasks
 *
 * @param {Promise<*>} request
 * @returns {any}
 * @throws {ApiError}
 */
export async function runTask(request) {
  if(!process.client) throw new Error('Worker on server')
  try {
    return await request
  } catch (e) {
    const error = new ApiError({}, null, true)
    Object.assign(error, e)
    error.worker = true
    error.isCancelled = error.isCancelled || e.message === 'canceled'

    if(error.statusCode === 401) {
      unauthorizedAction()
    } else if (error.statusCode === 429) {
      tooManyRequestsAction({message: error.getMessage()})
    }

    throw error
  }
}

/**
 *
 * @param {Promise<*>} request
 * @param {{}} config
 * @param {number} config.count
 * @param {boolean} config.worker
 * @returns {Promise<void>}
 */
export async function runFailableTask(request, config) {

}

/**
 * Parse array of data objects to given object Class
 *
 * @param Model {Codable}
 * @param dataSourceMethod {Promise}
 * @param parseMethod {Function}
 * @returns {Promise<FetchResult>}
 */
export async function parseArray(Model, dataSourceMethod, parseMethod = (item) => { return new Model(item) }) {
  /** @type {FetchResult} */
  const fetchResult = await dataSourceMethod
  fetchResult.data = fetchResult.data.map(parseMethod)
  return fetchResult
}

/**
 * Parse data object to given object Class
 *
 * @param Model {Codable}
 * @param dataSourceMethod {Promise}
 * @returns {Promise<*>}
 */
export async function parseObject(Model, dataSourceMethod) {
  const data = await dataSourceMethod
  return new Model(data)
}
