import map from 'lodash/map'
import sumBy from "lodash/sumBy";
import Article from "~/database/models/Article";
import LogService from '~/services/LogService'

const listViewCombinations = new Set()

const AFFILIATION_NAJADA = 'Najáda Eshop'

const GTM_EVENTS = {
  addToCart: 'addToCart',
  addShippingInfo: 'addShippingInfo',
  addPaymentInfo: 'addPaymentInfo',
  checkout: 'checkout',
  productClick: 'productClick',
  productView: 'productView',
  purchase: 'purchase',
  removeFromCart: 'removeFromCart',
  viewCart: 'viewCart',
  viewItemList: 'viewItemList',
}

export function eraseEcommerce() {
  window.dataLayer.push({ ecommerce: null })
}

/**
 *
 * @param {string} category
 * @param {string} action
 * @param {string?} label
 * @param {any?} value
 */
export function executeGAEvent(category, action, label, value) {
  eraseEcommerce()
  window.dataLayer.push({
    event: action,
    event_category: category,
    event_label: label,
    value
  })
}

export function executeGAPageView({title, path}) {
  eraseEcommerce()
  window.dataLayer.push({
    event: 'page.view',
    page_route_name: title,
    page_url: path,
  })
}

/**
 * Execute Google Analytics promotion click request
 *
 * @param {Article[]} articles
 * @param {string} currency
 * @param {string} route
 * @param {string} listId
 * @param {string} listName
 */
export function executeGAListView(articles, currency, route, listId = 'products_result', listName = 'Products result') {
  const products = []

  for (const article of articles) {
    const effectivePrice = article.priceLoc[currency.toLowerCase()] || 0
    const regularPrice = article.regularPriceLoc[currency.toLowerCase()] || 0
    const discount = regularPrice - effectivePrice

    const listViewIdentifier = `${route}-${article.id}`
    if(listViewCombinations.has(listViewIdentifier)) continue;
    listViewCombinations.add(listViewIdentifier)

    if(article.hasWotcSku) {
      const skus = article.wotcSkus
      for (const sku of skus) {
        products.push({
          id: article.sku,
          name: article.productObject.name,
          affiliation: AFFILIATION_NAJADA,
          currency: currency.toUpperCase(),
          quantity: 1,
          price: effectivePrice,
          list_id: listId,
          list_name: listName,
          wotc_sku: sku,
          language: article.languageCode,
          foil: article.isFoil,
          discount,
          route
        })
      }
    } else {
      products.push({
        id: article.sku,
        name: article.productObject.name,
        affiliation: AFFILIATION_NAJADA,
        currency: currency.toUpperCase(),
        quantity: 1,
        price: effectivePrice,
        list_id: listId,
        list_name: listName,
        wotc_sku: undefined,
        language: article.languageCode,
        foil: article.isFoil,
        discount,
        route
      })
    }
  }

  eraseEcommerce()
  window.dataLayer.push({
    event: GTM_EVENTS.viewItemList,
    totalValue: sumBy(articles, item => item.priceLoc[currency]),
    ecommerce: {
      currencyCode: currency.toUpperCase(),
      viewList: {
        products
      }
    }
  })
}

/**
 * Execute Google Analytics promotion click request
 *
 * @param {Article} article
 * @param {string} currency
 */
export function executeGAProductView(article, currency) {
  const products = []
  const wotcSkus = article.hasWotcSku ? article.wotcSkus : [null]
  const effectivePrice = article.priceLoc[currency.toLowerCase()] || 0
  const regularPrice = article.regularPriceLoc[currency.toLowerCase()] || 0
  const discount = regularPrice - effectivePrice

  for (const sku of wotcSkus) {
    products.push({
      id: article.sku,
      name: article.productObject.name,
      affiliation: AFFILIATION_NAJADA,
      currency: currency.toUpperCase(),
      quantity: article.stockItems,
      price: effectivePrice,
      wotc_sku: sku || undefined,
      name_sku: article.titleSKULoc.en,
      discount
    });
  }

  eraseEcommerce()
  window.dataLayer.push({
    event: GTM_EVENTS.productView,
    totalValue: effectivePrice,
    ecommerce: {
      currencyCode: currency.toUpperCase(),
      detail: {
        products
      }
    }
  })
}

/**
 * Execute Google Analytics promotion click request
 *
 * @param {Article} article
 * @param {string} currency
 */
export function executeGAPromotionView(article, currency, gtag) {
  // const effectivePrice = article.priceLoc[currency] || 0
  // const regularPrice = article.regularPriceLoc[currency] || 0
  // const discount = regularPrice - effectivePrice

  // gtag.event('view_promotion', {
  //   promotion_id: article.bubbleType,
  //   promotion_name: getBubbleTypeLocalized(article.bubbleType),
  //   items: [
  //     {
  //       item_id: article.sku,
  //       id: article.sku,
  //       item_name: article.productObject.name,
  //       name: article.productObject.name,
  //       affiliation: AFFILIATION_NAJADA,
  //       currency: currency.toUpperCase(),
  //       price: effectivePrice,
  //       discount,
  //     }
  //   ]
  // })
}

/**
 * Execute Google Analytics promotion click request
 *
 * @param {Article} article
 * @param {string} currency
 * @param {VueGtag} gtag
 */
export function executeGAPromotionSelect(article, currency, gtag) {
  // const effectivePrice = article.priceLoc[currency] || 0
  // const regularPrice = article.regularPriceLoc[currency] || 0
  // const discount = regularPrice - effectivePrice
  //
  // gtag.event('select_promotion', {
  //   promotion_id: article.bubbleType,
  //   promotion_name: getBubbleTypeLocalized(article.bubbleType),
  //   items: [
  //     {
  //       item_id: article.sku,
  //       id: article.sku,
  //       item_name: article.productObject.name,
  //       name: article.productObject.name,
  //       affiliation: AFFILIATION_NAJADA,
  //       currency: currency.toUpperCase(),
  //       discount,
  //       price: effectivePrice
  //     }
  //   ]
  // })
}

/**
 * Execute Google Analytics promotion click request
 *
 * @param {Article} article
 * @param {string} currency
 */
export function executeGAProductClick(article, currency) {
  const effectivePrice = article.priceLoc[currency.toLowerCase()] || 0
  const regularPrice = article.regularPriceLoc[currency.toLowerCase()] || 0
  const discount = regularPrice - effectivePrice

  eraseEcommerce()
  window.dataLayer.push({
    event: GTM_EVENTS.productClick,
    totalValue: effectivePrice,
    ecommerce: {
      currencyCode: currency.toUpperCase(),
      click: {
        products: [
          {
            id: article.sku,
            list: "Catalog products",
            name: article.productObject.name,
            affiliation: AFFILIATION_NAJADA,
            currency: currency.toUpperCase(),
            discount,
            price: effectivePrice
          }
        ]
      }
    }
  })
}

/**
 *
 * @param {string} event
 * @param {string} shipping
 * @param {string} payment
 * @param {Cart} cart
 * @param {string} currency
 */
export function executeGACheckoutProgress(event, shipping = null, payment = null, cart, currency) {
  eraseEcommerce()
  window.dataLayer.push({
    event,
    totalValue: cart.userTotalPriceNotRoundedLoc[currency.toLowerCase()],
    ecommerce: {
      currencyCode: currency.toUpperCase(),
      checkout: {
        value: cart.userTotalPriceNotRoundedLoc[currency.toLowerCase()],
        ...(cart.hasVoucherApplied && {coupon: cart.voucher.code || cart.promoVoucher.code}),
        ...(shipping && {shipping_tier: shipping}),
        ...(payment && {payment_type: payment}),
        products: map(cart.items, /** @param {CartItem} item */(item) => {
          return {
            id: item.article.id,
            name: item.article.product.name,
            quantity: item.count,
            price: item[`effective_unit_price_${currency.toLowerCase()}`],
            currency: currency.toUpperCase(),
            affiliation: AFFILIATION_NAJADA
          }
        })
      }
    }
  })
}

/**
 * @param {Cart} cart
 * @param {string} currency
 */
export function executeGACheckoutBegin(cart, currency) {
  executeGACheckoutProgress(GTM_EVENTS.checkout, null, null, cart, currency)
}

/**
 *
 * @param {Cart} cart
 * @param {string} payment
 * @param {string} currency
 */
export function executeGAAddPayment(cart, payment, currency) {
  executeGACheckoutProgress(GTM_EVENTS.addPaymentInfo, null, payment, cart, currency)
}

/**
 *
 * @param {Cart} cart
 * @param {string} shipping
 * @param {string} currency
 */
export function executeGAAddShipping(cart, shipping, currency) {
  executeGACheckoutProgress(GTM_EVENTS.addShippingInfo, shipping, null, cart, currency)
}

export function executeGAAddToCart(article, count, location = 'list', currency) {
  let products = [productTransformer(article, currency, location, count)]

  if(article.hasWotcSku) {
    const skus = article.wotcSkus
    products = []
    for (const sku of skus) {
      const articleCopy = new Article(article)
      articleCopy.wotc_sku = sku
      products.push(productTransformer(articleCopy, currency, location, count))
    }
  }

  eraseEcommerce()
  window.dataLayer.push({
    event: GTM_EVENTS.addToCart,
    totalValue: article.priceLoc[currency.toLowerCase()],
    ecommerce: {
      currencyCode: currency,
      add: {
        products
      }
    }
  })
}

/**
 * Execute Google analytics transaction request
 *
 * @param {Order} order
 */
export function executeGAPurchase(order) {
  eraseEcommerce()
  window.dataLayer.push({
    event: GTM_EVENTS.purchase,
    totalValue: order.displayPrice,
    ecommerce: {
      currencyCode: order.currency,
      purchase: {
        actionField: {
          id: order.id,
          affiliation: AFFILIATION_NAJADA,
          revenue: order.displayPrice,
          tax: 0,
          shipping: order.shippingMethodPrice,
          currency: order.currency,
        },
        products: map(order.orderItems, item => cartProductTransformer(item, undefined, undefined, order.currency))
      },
    },
  })
}

export function executeEhub (order, campaignId) {
  // eslint-disable-next-line no-undef
  const ehubSale = new EhubSale()
  ehubSale.setCampaignId(campaignId)
  ehubSale.setOrderId(`${order.id}`)
  ehubSale.setCurrency(order.currency)

  if(order.hasAppliedVoucher) {
    const voucher = order.appliedVouchers[0]
    ehubSale.setCouponCode(voucher.code)
    ehubSale.setCouponDiscount(voucher.discount(order.currency))
  }

  ehubSale.setPaymentMethod(order.paymentMethod.type)
  ehubSale.setNewCustomer(order.username === null ? 1 : 0)


  for(/** @type {OrderItem} */const item of order.orderItems) {
    ehubSale.addOrderItem({
      id: item.hasArticle ? item.articleModel.sku : item.id,
      category: item.categoryTag,
      name: item.hasArticle ? item.articleModel.name : item.customName,
      unitPrice: item.effectivePriceWithoutVat,
      quantity: item.count
    })
  }
  ehubSale.process()
}

/**
 * Set conversion transaction for Heureka
 *
 * @param {Order} order
 * @param {string} shopId
 */
export function executeHeureka(order, shopId) {
  // eslint-disable-next-line no-use-before-define
  window._hrq = window._hrq || []
  window._hrq.push(['setKey', shopId]);
  window._hrq.push(['setOrderId', order.id]);

  for(/** @type {OrderItem} */const item of order.orderItems) {
    window._hrq.push([
      'addProduct',
      item.hasArticle ? item.articleModel.name : item.customName, // NAZEV_PRODUKTU
      item.effectivePrice, // CENA ZA KUS
      item.count, // POCET KUSU
      item.hasArticle ? item.articleModel.sku : item.id // ITEM ID
    ]);
  }

  window._hrq.push(['trackOrder']);

  (function() {
    const ho = document.createElement('script')
    ho.type = 'text/javascript'; ho.async = true;
    ho.src = 'https://im9.cz/js/ext/1-roi-async.js';
    const s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(ho, s);
  })();
}

/**
 * Execute conversion request
 * @param {number} consent
 * @param {Order} order
 * @param {number} shopId
 * @param {number} sklikId
 * @param {boolean} sandbox
 */
export function executeSeznamZbozi({
  consent = 1,
  order,
  shopId,
  sklikId,
  sandbox = false,
}) {
  const conversionConf = {
    id: sklikId,
    orderId: `${order.id}`,
    value: order.displayPrice,
    zboziId: shopId,
    zboziType: sandbox ? 'sandbox' : 'standard',
    consent,
  }
  if (window.rc && window.rc.conversionHit) {
    window.rc.conversionHit(conversionConf)
  } else {
    LogService.error(new Error('Zbozi RC script missing from client'))
  }
}

/**
 *
 * @param {OrderItem} item
 * @param {number | null} count
 * @param {string | null} location
 * @param {string} currency
 * @returns {{quantity: number, affiliation: string, price: OrderItem.effective_unit_price, name: (*|SellOrderItem.custom_name|OrderItem.custom_name), currency, id: (string|(function(*): string|null)|*|null), category: *}}
 */
function cartProductTransformer(item, count, location, currency) {
  return {
    id: item.hasArticle ? item.articleModel.sku : item.id,
    name: item.hasArticle ? item.articleModel.titleLoc.cs : item.customName,
    category: item.categoryTag,
    quantity: count || item.count,
    price: item.effectivePrice,
    affiliation: AFFILIATION_NAJADA,
    currency,
    ...(location && {store_origin: location})
  }
}

function productTransformer(article, currency, location, count) {
  const effectivePrice = article.priceLoc[currency.toLowerCase()] || 0
  const regularPrice = article.regularPriceLoc[currency.toLowerCase()] || 0
  const discount = regularPrice - effectivePrice
  return {
    id: article.sku,
    name: article.productObject.name,
    affiliation: AFFILIATION_NAJADA,
    currency: currency.toUpperCase(),
    discount,
    price: effectivePrice,
    quantity: count,
    wotc_sku: article.wotc_sku,
    ...(location && {store_origin: location})
  }
}
