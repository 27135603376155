import each from 'lodash/each'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { slugify } from './Helpers'
import NavigationPage from '~/database/models/NavigationPage'

/**
 * Splits path to single fragments with non-empty values
 *
 * @param {string} path
 * @returns {string[]}
 */
export function splitPath(path) {
  return filter(path.split('/'), fragment => !isEmpty(fragment))
}

/**
 * Extracts product SKU from last route path fragment
 *
 * @param {string} path
 * @returns {string|null}
 */
export function extractProductSKUFromPath(path) {
  let sku = null
  const titleParts = path.split('-')

  if(!isEmpty(titleParts)) {
    sku = titleParts[titleParts.length - 1]
  }

  return sku
}

/**
 * Generates urls fragment for product detail
 * @param {string} name
 * @param {string} sku
 * @returns {string}
 */
export function titleSKU (name, sku) {
  return `${slugify(name)}-${sku}`
}

/**
 * Finds navigation pages for every url fragment and returns them in an array
 *
 * @param {string[]} fragments
 * @param {NavigationPage[]} menu
 * @returns {NavigationPage[]}
 */
export function pagesForPathFragments(fragments, menu) {
  let activeMenu = null
  const pagesPath = []

  each(fragments, fragment => {

    if(activeMenu === null) {
      activeMenu = menu.find(item => [item.url_fragment, item.url_fragment_cz].includes(fragment))
    } else {
      activeMenu = activeMenu?.children?.find(item => [item.url_fragment, item.url_fragment_cz].includes(fragment))
    }

    if(activeMenu) {
      pagesPath.push(new NavigationPage(activeMenu))
    }
  })

  return pagesPath
}

/**
 *
 * @param {{}} tree
 * @param {boolean} keepChildren
 * @returns {{}[]}
 */
export function flattenNavigation(tree, keepChildren) {
  let flatMap = {}

  flattenLinks(flatMap, tree)
  flatMap = map(flatMap, item => {
    const newItem = {...item}
    if(!keepChildren) {
      newItem.children = {}
    }

    return newItem
  })

  return flatMap
}


function flattenLinks (target, payload) {
  Object.keys(payload).forEach(key => {
    if ('full_url' in payload[key]) {
      target[payload[key].full_url] = payload[key]
    }

    if ('children' in payload[key]) {
      flattenLinks(target, payload[key].children)
    }
  })
}
