/**
 * Generates array of numbers from value to another value with step of 1
 *
 * @param {number} from
 * @param {number} to
 * @returns {number[]}
 */
import { ceilByDigits } from '~/services/Helpers'

export function stepMarksGenerator (from = 0, to = 1) {
  const values = []

  for (let i = from; i <= to; i++) {
    values.push(i)
  }

  return values
}

/**
 * Returns array of predefined price values for given currency
 *
 * @param {string} currency Lowercased currency
 * @returns {number[]}
 */
export function articlePriceMarks (currency = 'czk') {
  let marks = [0, 5, 10, 20, 50, 100, 200, 400, 600, 800, 1000]

  if (currency === 'eur') {
    marks = [0, 0.25, 0.5, 0.75, 1, 2, 4, 10, 20, 30, 40]
  }

  return marks
}

/**
 *
 * @param {{}} maxPrices
 * @param {number} maxPrices.czk
 * @param {number} maxPrices.eur
 * @param {('czk'|'eur')} currency Lowercased currency
 * @returns {number[]}
 */
export function extractPriceBoundaries (maxPrices = {}, currency = 'czk') {
  const baseCurrency = currency.toLowerCase()
  let boundaries = articlePriceMarks(currency)

  if (baseCurrency in maxPrices) {
    const max = maxPrices[baseCurrency]
    const percentageSteps = [0, 0.1, 0.2, 0.3, 0.5, 0.75, 1]
    const steps = []

    if(max > 0) {
      for (const percentage of percentageSteps) {
        const step = ceilByDigits(
          Math.round(percentage * max), baseCurrency === 'czk' ? 1 : 0
        )
        steps.push(step)
      }

      boundaries = [...steps]
    }
  }

  return boundaries
}
