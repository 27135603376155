import clone from 'lodash/clone'
import isArray from 'lodash/isArray'
import isEqual from 'lodash/isEqual'
import isNull from 'lodash/isNull'
import isString from 'lodash/isString'
import isEmpty from 'lodash/isEmpty'
import each from 'lodash/each'
import map from 'lodash/map'
import { stringify } from 'query-string'
import { articlePriceMarks } from '~/services/filters/SliderFilters'

/**
 * Create query string based on given object
 *
 * @param {Object} queryObject
 * @returns {String}
 */
export function queryString (queryObject) {
  return stringify(queryObject)
}

/**
 * Create query string based on given object without null values
 *
 * @param queryObject
 * @returns {String}
 */
export function queryStringNotNull (queryObject) {
  for (const key in queryObject) {
    if (key in queryObject && isNull(queryObject[key])) {
      delete queryObject[key]
    }
  }

  return queryString(queryObject)
}

/**
 *
 * @param {any} object
 * @returns {any}
 */
export function cloneDeep(object) {
  return JSON.parse(JSON.stringify(object))
}

/**
 *
 * @param {{}} filterModel
 * @param {NavigationFilter[]} filters
 * @returns {{}}
 */
export function serializePageNavigationFilter (filterModel, filters) {
  const model = {}

  each(filters, /** @type {NavigationFilter} */ filter => {
    const value = filterModel[`${filter.id}`]

    if(filter.isRange) {
      if(isArray(value) && value.length > 1 && !isEqual(value, [filter.rangeMin, filter.rangeMax])) {
        // Set only range filters which do have other value than default one (min, max)
        model[`${filter.id}`] = value.join('-')
      }
    } else if (filter.isSelect) {
      if(isArray(value) && !isEmpty(value)) {
        model[`${filter.id}`] = value.join(',')
      }
    }
  })

  return model
}

/**
 *
 * @param {{}} filterModel
 * @param {NavigationFilter[]} filters
 * @returns {string}
 */
export function pageNavigationFilterString (filterModel, filters) {
  const serializedModel = serializePageNavigationFilter(filterModel, filters)
  const valuesArray = map(serializedModel, (value, key) => {
    return [key, value].join(':')
  })
  return valuesArray.join(';')
}

/**
 * Create query for products based on given configuration object
 *
 * @param {{}} filterData
 * @returns {{}}
 */
export function serializeProductFilter (filterData, options = {}) {
  const data = clone(filterData)
  const priceItems = ['article_price', 'buy_price', 'price']
  const rangeItems = ['price', 'mtg_cmc', 'mtg_power', 'mtg_toughness']
  const csvItems = ['article_language', 'language', 'mtg_legal', 'article_condition', 'condition', 'product_tags', 'exclude_product_tags', 'product_category', 'product__expansion__game', 'product_expansion', 'expansion', 'product__rarity', 'rarity', 'mtg_artist', 'mtg_types', 'mtg_subtypes']
  const colorItems = ['mtg_colors']
  const foilItems = ['article_foil', 'is_foil']

  // Erase empty query items
  const filterKeys = Object.keys(data)
  each(filterKeys, key => {
    const dataValue = data[key]
    if (isArray(dataValue) && isEmpty(dataValue)) {
      delete data[key]
      return
    }
    if (isString(dataValue) && isEmpty(dataValue)) {
      delete data[key]
    }
  })

  // Supertype boolean converter
  if('supertype' in data) {
    for (const type of data.supertype) {
      data[`is_supertype_${type.toLowerCase()}`] = true
    }

    delete data.supertype
  }

  // Article and buy price translation
  for (let i = 0; i < rangeItems.length; i++) {
    const item = priceItems[i]

    if (item in data) {
      data[`${item}_min`] = data[item][0]
      data[`${item}_max`] = data[item][1]
      delete data[item]

      const czkPriceMarks = articlePriceMarks()
      const eurPriceMarks = articlePriceMarks('eur')

      if('priceBoundaries' in data && isArray(data.priceBoundaries)) {
        if(data[`${item}_max`] >= data.priceBoundaries[data.priceBoundaries.length - 1]) {
          data[`${item}_max`] = 10000000
        }

        delete data.priceBoundaries
      } else if (data[`${item}_max`] === czkPriceMarks[czkPriceMarks.length - 1] || data[`${item}_max`] === eurPriceMarks[eurPriceMarks.length - 1]) {
        data[`${item}_max`] = 10000000
      }
    }
  }

  // Range data transformation
  for (let i = 0; i < rangeItems.length; i++) {
    const item = rangeItems[i]

    if (item in data) {
      // TOUGHNESS AND POWER HACK
      if (item === 'mtg_toughness' && data[item][0] <= 1 && data[item][1] === 15) {
        delete data[item]
        continue
      }
      if (item === 'mtg_power' && data[item][0] <= 1 && data[item][1] === 15) {
        delete data[item]
        continue
      }
      if (item === 'mtg_cmc' && data[item][0] <= 1 && data[item][1] === 15) {
        delete data[item]
        continue
      }

      data[`${item}_min`] = data[item][0]
      data[`${item}_max`] = data[item][1]
      delete data[item]
    }
  }

  // CSV data transformation
  for (let i = 0; i < csvItems.length; i++) {
    const item = csvItems[i]

    if (item in data && isArray(data[item])) {
      data[item] = data[item].join()
    }
  }

  // Color data transformation
  for (let i = 0; i < colorItems.length; i++) {
    const item = colorItems[i]

    if (item in data && isArray(data[item])) {
      data[item] = data[item].join('')

      if (data[item] === 'or' || data[item] === 'and') {
        // Dont send empty filter
        delete data[item]
      } else {
        data[item] = data[item].replace('and', '')
      }
    }
  }

  // Foil array items
  for (let i = 0; i < foilItems.length; i++) {
    const item = foilItems[i]

    if(item in data && isArray(data[item])) {
      const hasBoth = data[item].includes('true') && data[item].includes('false')

      if (hasBoth) {
        delete data[item]
      } else {
        data[item] = data[item].includes('true')
      }
    }
  }

  if ('q' in data && data.q) {
    data.q = data.q.trim()
    delete data.cardName
  }

  const {replaceRules} = options

  if(replaceRules && replaceRules.length > 0) {
    const filterKeys = Object.keys(data)
    for (const {from, to} of replaceRules) {
      for (const filterKey of filterKeys) {
        if(filterKey.startsWith(from)) {
          data[filterKey.replace(from, to)] = data[filterKey]
          delete data[filterKey]
        }
      }
    }
  }

  return data
}

/**
 *
 * @param {{}} filter
 * @returns {string}
 */
export function productFilterString (filter) {
  return stringify(serializeProductFilter(filter))
}

export const searchTagsAvailable = [
  'expansion',
  'supertype',
  'rarity',
  'mtg_colors',
  'mtg_legal',
  'mtg_subtypes',
  'mtg_types',
  'article_condition',
  'article_language',
  'article_foil',
  'is_foil',
  'mtg_artist'
]

/**
 *
 * @param {Number} from
 * @param {Number} to
 * @return {Array<Number>}
 */
export function stepMarksGenerator (from = 0, to = 1) {
  const values = []

  for (let i = from; i <= to; i++) {
    values.push(i)
  }

  return values
}

export const nonSinglesFilterKeys = ['product_expansion', 'product__expansion__game', 'product_category', 'product_tags']

export const sortKeys = {
  nameAsc: 'name',
  nameDesc: '-name',
  priceAsc: 'price',
  priceDesc: '-price',
  addedAsc: 'added',
  addedDesc: '-added',
  popularityAsc: 'popularity',
  popularityDesc: '-popularity',
  releaseAsc: 'release',
  releaseDesc: '-release',
  sellReleaseAsc: 'release',
  sellReleaseDesc: '-release',
  discount: 'discount',
  collnumAsc: 'collnum',
  collnumDesc: '-collnum'
}
