import { get } from '~/services/network/utils/axios'

export function getOffer({id, cancelSignal}) {
  return get({
    url: '/najada2/buylist-offers/{id}/',
    id,
    cancelSignal
  })
}

export function getOffers({query, cancelSignal}) {
  return get({
    url: '/najada2/buylist-offers/',
    query,
    cancelSignal
  })
}
