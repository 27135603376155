import each from 'lodash/each'
import findIndex from 'lodash/findIndex'
import forIn from 'lodash/forIn'
import isArray from 'lodash/isArray'
import isNull from 'lodash/isNull'
import round from 'lodash/round'
import isObject from 'lodash/isObject'
import { stringifyUrl } from 'query-string'

/**
 *
 * @type {Map<string, string>}
 */
export const langMap = new Map([
  ['cs', 'cz'],
  ['en', 'en'],
  ['de', 'de']
])

/**
 *
 * @type {Map<string, string>}
 */
export const countryMap = new Map([
  ['cz', 'cs'],
  ['en', 'en'],
  ['de', 'de']
])

/**
 *
 * @param {number} value
 * @returns {string}
 */
export function thousandsSeparator(value) {
  const numParts = value.toString().split('.')
  numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  return numParts.join('.')
}

/**
 *
 * @param {number} value
 * @returns {string}
 */
export function numberFormatter(value, decimals = 2) {
  return thousandsSeparator(round(Math.abs(value), decimals))
}

/**
 *
 * @param promise
 * @returns {*}
 */
export function lazyComp (promise) {
  return promise.then(m => m.default || m);
}

/**
 *
 * @param {string} str
 * @returns {string}
 */
export function stripTrailingSlash(str) {
  return str.endsWith('/') ? str.slice(0, -1) : str
}

/**
 * Changes first letter of a string to upper case
 * @param {string} string
 * @returns {string}
 */
export function firstLetterUppercase (string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 *
 * @param {string} key
 * @param {string} language
 * @param {{}} object
 */
export function getLocalizedContent(key, language, object) {
  let appendix = ''

  if (language !== 'en') {
    if (langMap.has(language)) {
      appendix = `_${langMap.get(language)}`
    }
  }

  const property = `${key}${appendix}`

  if (property in object) {
    return object[`${property}`]
  }

  return null
}

/**
 *
 * @param {number} pieces
 * @param {boolean} short
 * @returns {string}
 */
export function piecesString (pieces, short = false) {
  let piecesString = 'general_pieces_3'

  if (short) {
    piecesString = 'general_pieces_short'

    if (pieces > 1) {
      piecesString = piecesString + '_pl'
    }
  } else if (pieces === 1) {
    piecesString = 'general_pieces_1'
  } else if (pieces > 1 && pieces < 5) {
    piecesString = 'general_pieces_2'
  }

  return piecesString
}

export function czechPluralString(base, count) {
  let itemsString = `${base}_3`

  if (count === 1) {
    itemsString = `${base}_1`
  } else if (count > 1 && count < 5) {
    itemsString = `${base}_2`
  }

  return itemsString
}

export function daysString (days) {
  return czechPluralString('general_day', days)
}

export function itemsString (count) {
  return czechPluralString('general_items', count)
}

export function items6thString (count) {
  return czechPluralString('general_items_6th', count)
}

export function resultsString (count) {
  return czechPluralString('general_result', count)
}

export function parametersString (count) {
  return czechPluralString('general_parameter', count)
}

export function nextsString (count) {
  return czechPluralString('general_next', count)
}

export function nexts6thString (count) {
  return czechPluralString('general_next_6th', count)
}

export function criterionsString (count) {
  return czechPluralString('general_criterion', count)
}

export function isPickedString (count) {
  return czechPluralString('general_is_picked', count)
}

export function stripTags (string) {
  return string.replace(/(<([^>]+)>)/gi, '').replace(/\s/g, ' ').trim()
}

/**
 *
 * @param {string} text
 * @returns {string}
 */
export function slugify (text) {
  return text
    .toString() // Cast to string
    .toLowerCase() // Convert the string to lowercase letters
    .normalize('NFD') // The normalize() method returns the Unicode Normalization Form of a given string.
    .trim() // Remove whitespace from both sides of a string
    .replace(/\s+/g, '-') // Replace spaces with -
    // eslint-disable-next-line no-useless-escape
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    // eslint-disable-next-line no-useless-escape
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
}

/**
 *
 * @param {any[]} objects
 * @param {Function} findMethod
 * @returns {*}
 */
export function findObject (objects, findMethod) {
  const index = findIndex(objects, item => findMethod(item))
  return index > -1 ? objects[index] : null
}

/**
 * Map object data by given property map
 *
 * @param {Map} map
 * @param {Object} object
 * @param {Object} parameters
 * @returns {*}
 */
export function mapData (map, object, parameters = {}) {
  forIn(object, (value, key) => {
    if (map.has(key)) {
      parameters[map.get(key)] = value
    } else {
      parameters[key] = value
    }
  })

  return parameters
}

/**
 *
 * @param {{}} object
 * @returns {{}}
 */
export function removeObjectNulls (object) {
  const resultObject = { ...object }

  const keys = Object.keys(resultObject)
  for (let i = 0; i < keys.length; i++) {
    if (isNull(resultObject[keys[i]])) {
      delete resultObject[keys[i]]
    }
  }

  return resultObject
}

/**
 * Promise method waiting for state change
 *
 * @param {Function} conditionFunction
 * @return {Promise<any>}
 */
export function until (conditionFunction) {
  const poll = resolve => {
    if (conditionFunction()) resolve()
    // eslint-disable-next-line no-unused-vars
    else setTimeout(_ => poll(resolve), 150)
  }

  return new Promise(poll)
}

/**
 *
 * @param {boolean} branding Branding
 * @param {Object} params
 * @param {string|number} params.accountNumber Bank account number
 * @param {string|number} params.bankCode Bank code, e.g. 0100
 * @param {string|number} params.amount Amount
 * @param {string} params.currency Currency
 * @param {string} params.vs Variabilni symbol
 * @param {string?} params.message Zprava
 * @returns {string}
 */
export function generatePaymentQRCode ({ branding = false, ...params }) {
  return stringifyUrl({
    url: 'https://api.paylibo.com/paylibo/generator/czech/image',
    query: {
      branding,
      ...params
    }
  })
}

/**
 *
 * @param {{}} target
 * @param {string[]} keys
 * @returns {{}}
 */
export function keepKeysOnly(target, keys) {
  const targetCopy = {}

  each(keys, keyRepresentative => {
    const stepKeys = keyRepresentative.split('.')
    const firstKey = stepKeys[0]
    const secondKey = stepKeys.length > 1 ? stepKeys[1] : null

    if(firstKey in target) {
      if(secondKey && secondKey in target[firstKey]) {
        if(firstKey in targetCopy) {
          targetCopy[firstKey][secondKey] = target[firstKey][secondKey]
        } else {
          targetCopy[firstKey] = {}
          targetCopy[firstKey][secondKey] = target[firstKey][secondKey]
        }
      } else {
        targetCopy[firstKey] = target[firstKey]
      }
    }
  })

  return targetCopy
}

/**
 * Round number to exact number of decimals
 *
 * @param {number} number
 * @param {number} decimals
 * @returns {number}
 */
export function roundToDecimals (number, decimals) {
  const divider = Math.pow(10, decimals)
  return Math.round(number * divider) / divider
}

/**
 * Round number by digits
 *
 * @param {number} number
 * @param {number} digits
 * @returns {number}
 */
export function roundByDigits (number, digits) {
  const divider = Math.pow(10, digits)
  return Math.round(number / divider) * divider
}

/**
 * Round up number by digits
 *
 * @param {number} number
 * @param {number} digits
 * @returns {number}
 */
export function ceilByDigits (number, digits) {
  const divider = Math.pow(10, digits)
  return Math.ceil(number / divider) * divider
}

export function isOverflownHorizontal ({ clientWidth, scrollWidth }) {
  return scrollWidth > clientWidth
}


export function isOverflownVertical ({ clientHeight, scrollHeight }) {
  return scrollHeight > clientHeight
}

export function waitTimeout(time = 0) {
  return new Promise((resolve) => {
    setTimeout(function (){ resolve() }, time)
  })
}

export function asyncTimeout(time = 0) {
  return new Promise((resolve) => {
    setTimeout(function () {
      resolve()
    }, time)
  })
}

export function extractFunctionalClass(staticClass = '', dynamicClass = {}) {
  let dynamicClassObject = {
    [staticClass || '']: true
  }

  if (isArray(dynamicClass)) {
    dynamicClassObject[dynamicClass.join(' ')] = true
  } else if(isObject(dynamicClass)) {
    dynamicClassObject = dynamicClass
  }

  return dynamicClassObject
}

/**
 *
 * @param {string} time
 * @return {number}
 */
export function getNumberFromTime(time) {
  return +time.replace(/:/g, '')
}

export function generateUUID() {
  let
    d = new Date().getTime();
    let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    let r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x7 | 0x8)).toString(16);
  });
}
