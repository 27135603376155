import app from './app.json'

export default {
  ...app,
  cookiebot: {
    id: '77de5c15-0ab8-4ab6-b13b-592f4d3ccd65'
  },
  ecommerce: {
    googleMerchant: {
      surveyLink: 'https://g.page/r/CeJxvMCUcKbaEBM/review',
      id: '547127491',
    },
  },
  features: {
    christmasTheme: {
      enabled: false,
    },
    gamingClub: {
      enabled: true,
    },
  },
  social: {
    links: {
      facebook: 'https://www.facebook.com/Najadacz-109445939080479',
      discord: 'https://discord.gg/EYwHSf7gAf',
      instagram: 'https://www.instagram.com/najada.games/',
      tiktok: 'https://www.tiktok.com/@najadagames',
      youtube:
        'https://www.youtube.com/channel/UCFhZrN4OtB82t4Qra_sMkWQ/featured',
    },
  },
}
