import { get, patch, post } from '~/services/network/utils/axios'

export function getLeagues({query, cancelSignal}) {
  return get({
    url: 'playhouse/league/',
    query,
    cancelSignal
  })
}

export function getLeague({id, cancelSignal}) {
  return get({
    url: 'playhouse/league/{id}/',
    id,
    cancelSignal
  })
}

export function getSeasons({query, cancelSignal}) {
  return get({
    url: 'playhouse/season/',
    query,
    cancelSignal
  })
}

export function getActiveSeason({query, cancelSignal}) {
  return get({
    url: 'playhouse/season/active/',
    query,
    cancelSignal
  })
}

export function getSeason({id, cancelSignal}) {
  return get({
    url: 'playhouse/season/{id}/',
    id,
    cancelSignal
  })
}

export function getTournaments({query, cancelSignal}) {
  return get({
    url: 'playhouse/tournament/',
    query,
    cancelSignal
  })
}

export function getTournament({id, cancelSignal}) {
  return get({
    url: 'playhouse/tournament/{id}/',
    id,
    cancelSignal
  })
}

export function tournamentEnroll({id}) {
  return post({
    url: 'playhouse/tournament/{id}/enroll/',
    id
  })
}

export function tournamentDisenroll({id}) {
  return post({
    url: 'playhouse/tournament/{id}/disenroll/',
    id
  })
}

export function tournamentUploadDecklist ({ id, data }) {
  return post({
    url: 'playhouse/tournament/{id}/upload-decklist/',
    multipart: true,
    id,
    data
  })
}

export function getPlayerProfiles({query, cancelSignal}) {
  return get({
    url: 'playhouse/player-profile/',
    query,
    cancelSignal
  })
}

export function getPlayerProfile({id, cancelSignal}) {
  return get({
    url: 'playhouse/player-profile/{id}/',
    id,
    cancelSignal
  })
}

export function getPlayerProfileStats({id, query, cancelSignal}) {
  return get({
    url: 'playhouse/player-profile/{id}/stats/',
    id,
    query,
    cancelSignal
  })
}

export function getPlayerProfileCompare({id, query, cancelSignal}) {
  return get({
    url: 'playhouse/player-profile/{id}/compare/',
    id,
    query,
    cancelSignal
  })
}

export function registerPlayerProfile({data}) {
  return post({
    url: 'playhouse/player-profile/register/',
    data
  })
}

export function getPlayerProfileMe({cancelSignal}) {
  return get({
    url: 'playhouse/player-profile/me/',
    cancelSignal
  })
}

export function patchPlayerProfile({id, data}) {
  return patch({
    url: 'playhouse/player-profile/{id}/',
    id,
    data
  })
}

export function getGames({data, cancelSignal}) {
  return get({
    url: 'playhouse/game/',
    data,
    cancelSignal
  })
}

export function getFormats({data, cancelSignal}) {
  return get({
    url: 'playhouse/format/',
    data,
    cancelSignal
  })
}

export function getSeasonSummary({query, cancelSignal}) {
  return get({
    url: 'playhouse/season/summary/',
    query,
    cancelSignal
  })
}

export function getSeasonStandings({query, cancelSignal}) {
  return get({
    url: 'playhouse/seasons-standing/',
    query,
    cancelSignal
  })
}

export function getSeasonStandingAll({query, cancelSignal}) {
  return get({
    url: 'playhouse/seasons-standing/all/',
    query,
    cancelSignal
  })
}

export function getSeasonStanding({id, cancelSignal}) {
  return get({
    url: 'playhouse/seasons-standing/{id}/',
    id,
    cancelSignal
  })
}

export function getAchievements({query, cancelSignal}) {
  return get({
    url: 'playhouse/achievement/',
    query,
    cancelSignal
  })
}

export function getMyAchievements({query, cancelSignal}) {
  return get({
    url: 'playhouse/achievement/me/',
    query,
    cancelSignal
  })
}

export function getAchievementsSummary({query, cancelSignal}) {
  return get({
    url: 'playhouse/achievement/summary/',
    query,
    cancelSignal
  })
}

export function getAchievement({id, cancelSignal}) {
  return get({
    url: 'playhouse/achievement/{id}/',
    id,
    cancelSignal
  })
}
