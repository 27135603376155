import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import FetchResult from '~/structures/FetchResult'

export function responseDataInterceptor (response) {
  const { data = {} } = response
  if(data && isObject(data)) {
    if ('next' in data) {
      data.hasMore = data.next !== null
      if (data.next) {
        data.hasMore = true
      }
    }
  }

  return data
}

export function responseFetchResultInterceptor (data) {
  let responseData = JSON.parse(JSON.stringify(data))

  if (isArray(responseData.results) || isArray(responseData)) {
    responseData = new FetchResult({
      data: responseData.results || responseData || [],
      params: responseData.params || {},
      summary: responseData.search_summary || {},
      total: responseData.count || 0,
      hasMore: responseData.hasMore
    })
  }

  return responseData
}
