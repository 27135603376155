import isNull from 'lodash/isNull'

export function hasFb () {
  return !!window.FB
}

/**
 *
 * @param {{}} props
 * @param {string} props.appId
 * @param {boolean} props.cookie
 * @param {boolean} props.xfbml
 * @param {string} props.version
 */
export function init (props) {
  window.FB.init(props)
}

/**
 *
 * @returns {string|null}
 */
export function getAccessToken () {
  if(!hasFb()) return null
  return window.FB.getAccessToken()
}

export function hasToken () {
  return !isNull(getAccessToken())
}

/**
 *
 * @returns {Promise<unknown>}
 */
export function getLoginStatus () {
  return new Promise(window.FB.getLoginStatus)
}

/**
 * Logs user in for current session
 * @returns {Promise<unknown>}
 */
export function login () {
  return new Promise((resolve, reject) => {
    window.FB.login(function (response) {
      resolve(response)
    }, { scope: 'email' })
  })
}

/**
 * Logs user out of current session
 * @returns {*}
 */
export function logout () {
  if(!hasFb()) return null
  return window.FB?.logout()
}

/**
 * Logs user out of current session
 * @returns {*}
 */
export function revokePermissions (token, userId = '10221501325862809') {
  return new Promise((resolve, reject) => {
    window.FB.api(`/${userId}/permissions`, 'delete', {
      access_token: token
    }, function (response) {
      resolve(response)
    })
  })
}
