import Vue from 'vue'
import isArray from 'lodash/isArray'

import SinglesFilter from '@/structures/filters/SinglesFilter'
import NonSinglesFilter from '@/structures/filters/NonSinglesFilter'
import BuylistSinglesFilter from '@/structures/filters/BuylistSinglesFilter'
import BulkSettingsFilter from '~/structures/filters/BulkSettingsFilter'
import SidebarSummaryItem from '~/structures/SidebarSummaryItem'
import SummaryActiveManager from '~/structures/search/SummaryActiveManager'
import { nonSinglesFilterKeys } from '@/services/FilterService'
import CMSArticleFilter from '@/structures/filters/CMSArticleFilter'

/**
 * @typedef {string} EventBus.SearchSection
 * @enum {EventBus.SearchSection}
 */
export const SearchSection = {
  products: 'products',
  looseCards: 'loose_cards',
  lorcanaSingles: 'lorcana_singles'
}

export const BusEvents = {
  app: {
    windowResize: 'WINDOW_RESIZE'
  },
  search: {
    inputChange: 'SEARCH_INPUT_CHANGE',
    sectionChange: 'SEARCH_SECTION_CHANGE',
    filterChange: 'SEARCH_FILTER_CHANGE',
    countChange: 'SEARCH_COUNT_CHANGE',
    summaryChange: 'SEARCH_SUMMARY_CHANGE',
    summaryActiveChange: 'SEARCH_SUMMARY_ACTIVE_CHANGE',
    sectionAvailabilityChange: 'SEARCH_SECTION_AVAILABILITY_CHANGE'
  },
  bulk: {
    fetching: 'BULK_PURCHASE_FETCHING',
    purchaseRefreshRequested: 'BULK_PURCHASE_REFRESH_REQUESTED',
    buylistRefreshRequested: 'BULK_PURCHASE_REFRESH_REQUESTED',
  },
  singles: {
    filterChange: 'SINGLES_FILTER_CHANGE'
  },
  blog: {
    filterChange: 'BLOG_FILTER_CHANGE'
  },
  buylist: {
    filterChange: 'BUYLIST_FILTER_CHANGE',
    expansionChange: 'BUYLIST_FILTER_EXPANSION_CHANGE'
  },
  sideNavigation: {
    navigationChanged: 'SIDE_NAVIGATION_CHANGED'
  },
  breadcrumbs: {
    remapRequst: 'BREADCRUMBS_REMAP_REQUEST'
  }
}

export const EventBus = new Vue({
  data () {
    return {
      general: {
        // Used in models registered in ORM store
        currency: 'CZK',
        language: 'cs'
      },

      search: {
        input: '',
        titleSection: '',
        term: '',
        section: 'products',
        sectionsLocked: false,
        sectionsAvailable: {
          products: true,
          loose_cards: true,
          lorcana_singles: true
        },
        count: {
          products: 0,
          loose_cards: 0,
          lorcana_singles: 0
        },
        filter: {
          products: new NonSinglesFilter(),
          singles: new SinglesFilter(),
          lorcanaSingles: new SinglesFilter(),
        },
        shouldRemapSummary: true,
        summary: {},
        summaryActiveItems: [],
        summaryActiveManager: new SummaryActiveManager({ keys: nonSinglesFilterKeys })
      },

      list: {
        title: '',
        summary: {}
      },

      bulkParser: {
        purchase: {
          section: '',
          settings: new BulkSettingsFilter()
        },
        buylist: {
          settings: new BulkSettingsFilter()
        }
      },

      singles: {
        filter: new SinglesFilter()
      },

      buylist: {
        filter: new BuylistSinglesFilter()
      },

      blog: {
        filter: new CMSArticleFilter()
      },

      breadcrumbs: {
        items: []
      },

      sideNavigation: {
        parent: '',
        children: []
      },

      specialPages: {
        config: {
          forcedForDetail: false,
          forcedPageId: null
        },
        new: {
          items: [],
          activeItems: [],
          total: 0
        },
        presale: {
          items: [],
          activeItems: [],
          total: 0
        },
        discounted: {
          items: [],
          activeItems: [],
          total: 0
        }
      }
    }
  },
  computed: {
    language(vm) {
      return vm.general.language
    },
    currencyLower (vm) {
      return vm.general.currency.toLowerCase()
    },
    productExpansion (vm) {
      if ('product_expansion' in vm.search.filter.products && isArray(vm.search.filter.products.product_expansion)) {
        return vm.search.filter.products.product_expansion
      }

      return []
    }
  },
  watch: {
    'search.input': {
      immediate: true,
      handler () {
        this.$emit(BusEvents.search.inputChange, this.search.input)
      }
    }
  },
  methods: {
    bulkPurchaseFetching(value) {
      this.$emit(BusEvents.bulk.fetching, value)
    },

    requestBreadcrumbsRemap() {
      this.$emit(BusEvents.breadcrumbs.remapRequst)
    },

    requestBulkPurchaseRefresh() {
      this.$emit(BusEvents.bulk.purchaseRefreshRequested)
    },
    requestBulkBuylistRefresh() {
      this.$emit(BusEvents.bulk.buylistRefreshRequested)
    },

    setBulkPurchaseFilterItem(key, value) {
      this.bulkParser.purchase.settings.setKey(key, value)
    },

    /**
     * @param {string} page
     * @param {Array} items
     * @param {number} total
     */
    setSpecialPageItems (page, items, total = 0) {
      this.specialPages[page].items = [
        new SidebarSummaryItem({
          id: '-1',
          name: 'search_category-all',
          name_cz: 'search_category-all',
          name_de: 'search_category-all',
          game: null,
          total_count: total
        }),
        ...items
      ]

      this.$emit(BusEvents.sideNavigation.navigationChanged, {})
    },

    setSpecialPageActiveItem (page, itemId) {
      this.specialPages[page].activeItems = itemId && itemId !== '-1' ? [itemId] : []
      this.$emit(BusEvents.sideNavigation.navigationChanged, {})
    },

    /**
     * Set one filter item for search view
     *
     * @param key
     * @param value
     */
    setProductFilterItem (key, value) {
      this.search.filter.products.setKey(key, value)
      this.search.summaryActiveManager.setItems(key, value)
      // this.search.summaryActiveItems = [...this.search.summaryActiveItems, ...value]
      this.$emit(BusEvents.search.filterChange, this.search.filter.products)
    },

    /**
     * Set one filter item for search view
     *
     * @param {string} key
     * @param {any} value
     */
    setSearchSinglesFilterItem (key, value) {
      this.search.filter.singles.setKey(key, value)
      this.$emit(BusEvents.search.filterChange, this.search.filter.singles)
    },
    setSearchSinglesLorcanaFilterItem (key, value) {
      this.search.filter.lorcanaSingles.setKey(key, value)
      this.$emit(BusEvents.search.filterChange, this.search.filter.lorcanaSingles)
    },

    /**
     * Set filters for custom list view showing articles
     *
     * @param {object} filter
     */
    setSearchSinglesFilterItems (filter = {}) {
      this.search.filter.singles.setKeys(filter)
      this.$emit(BusEvents.search.filterChange, this.search.filter.singles)
    },
    setSearchSinglesLorcanaFilterItems (filter = {}) {
      this.search.filter.lorcanaSingles.setKeys(filter)
      this.$emit(BusEvents.search.filterChange, this.search.filter.lorcanaSingles)
    },

    /**
     * Remove one filter item from selected values
     * @param key
     * @param value
     */
    removeSearchSinglesFilterItem (key, value) {
      this.search.filter.singles.removeArrayKey(key, value)
      this.$emit(BusEvents.search.filterChange, this.search.filter.singles)
    },
    removeSearchSinglesLorcanaFilterItem (key, value) {
      this.search.filter.lorcanaSingles.removeArrayKey(key, value)
      this.$emit(BusEvents.search.filterChange, this.search.filter.singles)
    },

    /**
     * Reset filter for search view
     *
     * @param {string} currency
     */
    resetSearchSinglesFilter (currency = 'czk') {
      this.search.filter.singles.reset(currency)
      this.$emit(BusEvents.search.filterChange, this.search.filter.singles)
    },
    resetSearchSinglesLorcanaFilter (currency = 'czk') {
      this.search.filter.lorcanaSingles.reset(currency)
      this.$emit(BusEvents.search.filterChange, this.search.filter.singles)
    },

    /**
     * Reset filter for search view
     *
     * @param {string} currency
     */
    resetSearchNonSinglesFilter (currency = 'czk') {
      this.search.filter.products.reset(currency)
      this.$emit(BusEvents.search.filterChange, this.search.filter.products)
    },

    /**
     * Set filter for custom list view showing articles
     *
     * @param {string} key
     * @param {any} value
     */
    setBlogFilterItem (key, value) {
      this.blog.filter.setKey(key, value)
      this.$emit(BusEvents.blog.filterChange, this.blog.filter)
    },

    /**
     * Set filter for custom list view showing articles
     *
     * @param {{}} filters
     */
    setBlogFilterItems (filters = {}) {
      this.blog.filter.setKeys(filters)
      this.$emit(BusEvents.blog.filterChange, this.blog.filter)
    },

    /**
     * Reset filter for search view
     *
     * @param {string} currency
     */
    resetBlogFilter (currency = 'czk') {
      this.blog.filter.reset(currency)
      this.$emit(BusEvents.blog.filterChange, this.blog.filter)
    },

    /**
     * Set filter for custom list view showing articles
     *
     * @param {string} key
     * @param {any} value
     */
    setSinglesFilterItem (key, value) {
      this.singles.filter.setKey(key, value)
      this.$emit(BusEvents.singles.filterChange, this.singles.filter)
    },

    /**
     * Set filters for custom list view showing articles
     *
     * @param {object} filter
     */
    setSinglesFilterItems (filter = {}) {
      this.singles.filter.setKeys(filter)
      this.$emit(BusEvents.singles.filterChange, this.singles.filter)
    },

    /**
     * Remove one filter from selected values for Singles filter
     * @param {String} key
     * @param {any} value
     */
    removeSinglesFilterItem (key, value) {
      this.singles.filter.removeArrayKey(key, value)
      this.$emit(BusEvents.singles.filterChange, this.singles.filter)
    },

    /**
     * Reset filter for search view
     *
     * @param {string} currency
     */
    resetSinglesFilter (currency = 'czk') {
      this.singles.filter.reset(currency)
      this.$emit(BusEvents.singles.filterChange, this.singles.filter)
    },

    /**
     * Set filter for custom list view showing buylist items
     * @param {string} key
     * @param {any} value
     */
    setBuylistFilterItem (key, value) {
      this.buylist.filter.setKey(key, value)
      this.$emit(BusEvents.buylist.filterChange, this.buylist.filter)
    },

    /**
     * Set filters for custom list view showing articles
     *
     * @param {object} filter
     */
    setBuylistFilterItems (filter = {}) {
      this.buylist.filter.setKeys(filter)
      this.$emit(BusEvents.buylist.filterChange, this.buylist.filter)
    },

    /**
     * Reset filter for search view
     *
     * @param {string} currency
     */
    resetBuylistSinglesFilter (currency = 'czk') {
      this.buylist.filter.reset(currency)
      this.$emit(BusEvents.buylist.filterChange, this.buylist.filter)
    },

    /**
     * Remove one filter from selected values for Buylist filter
     *
     * @param {String} key
     * @param {any} value
     */
    removeBuylistFilterItem (key, value) {
      this.buylist.filter.removeArrayKey(key, value)
      this.$emit(BusEvents.buylist.filterChange, this.buylist.filter)
    },

    /**
     *
     * @param breadcrumbsArray
     */
    setBreadcrumbs (breadcrumbsArray = []) {
      if (isArray(breadcrumbsArray)) {
        this.breadcrumbs.items = breadcrumbsArray
      }
    },

    /**
     *
     * @param parent
     * @param {Array<String>} children
     */
    setSideNavigation (parent, children = []) {
      this.sideNavigation.parent = parent
      this.sideNavigation.children = children
      this.$emit(BusEvents.sideNavigation.navigationChanged, {
        parent,
        children
      })
    },

    /**
     * Toggle active item in search items
     *
     * @param {String} itemId
     * @param {String} filterName
     */
    toggleActiveSummaryArray (itemId, filterName) {
      this.search.summaryActiveManager.toggleItem(filterName, itemId)
      this.toggleProductFilterItem(`${itemId}`, filterName)
      this.$emit(BusEvents.search.summaryActiveChange)
    },

    /**
     *
     * @param {String} itemId
     * @param {String} filterName
     */
    toggleProductFilterItem (itemId, filterName) {
      this.search.filter.products.toggleArrayKey(filterName, itemId)

      this.$emit(BusEvents.search.filterChange, this.search.filter.products)
    },

    /**
     * Assigns search summary object for search page
     * @param {{string: [{}]}}summary
     */
    setSearchSummary(summary) {
      this.search.summary = summary
      this.$emit(BusEvents.search.summaryChange, summary)
    },

    /**
     * Remove all selected items from array
     */
    resetSummaryActiveItems () {
      this.search.summaryActiveManager.reset()
      this.$emit(BusEvents.search.summaryActiveChange)
    },

    /**
     * Set search section
     *
     * @param {EventBus.SearchSection} section
     */
    setActiveSearchSection (section) {
      this.search.section = section
      this.$emit(BusEvents.search.sectionChange, section)
      // EventBus.search.summary = {}
    },

    /**
     * Set total number of items for searched term in given section
     *
     * @param {EventBus.SearchSection} section
     * @param {Number} count
     */
    setSearchCount (section, count) {
      this.search.count[section] = count
      this.$emit(BusEvents.search.countChange)
    },

    /**
     * Reset sections count
     */
    resetSearchCount () {
      this.search.count[SearchSection.products] = 0
      this.search.count[SearchSection.looseCards] = 0
      this.$emit(BusEvents.search.countChange)
    },

    /**
     * Reset sections setting
     */
    resetSectionSetting () {
      this.search.sectionsLocked = false
      this.search.sectionsAvailable.lorcana_singles = true
      this.search.sectionsAvailable.loose_cards = true
      this.search.sectionsAvailable.products = true
      this.$emit(BusEvents.search.sectionAvailabilityChange, this.search.sectionsAvailable)
    },

    /**
     * Set search section as disabled/enabled
     *
     * @param {EventBus.SearchSection} section
     * @param {Boolean} value
     */
    toggleSectionAvailability (section, value) {
      if (this.search.sectionsLocked) return
      this.search.sectionsAvailable[section] = value
      this.$emit(BusEvents.search.sectionAvailabilityChange, this.search.sectionsAvailable)
    },

    /**
     * Disable/enable manipulation with search available sections
     */
    lockSearchSectionsChanges () {
      this.search.sectionsLocked = true
    },

    /**
     *
     * @param {Number} width
     * @param {Number} height
     */
    windowSizeChanged (width, height) {
      this.$emit(BusEvents.app.windowResize, { width, height })
    }
  }
})

export default EventBus
