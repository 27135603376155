import { get, post } from '~/services/network/utils/axios'

export function getCmsArticle({id, cancelSignal}) {
  return get({
    url: 'najada2/cms-articles/{id}/',
    id,
    cancelSignal,
    config: {
      headers: {
        NoAuth: true
      }
    }
  })
}

export function setCmsArticleRead({id, data}) {
  return post({
    url: 'najada2/cms-articles/{id}/read/',
    id,
    data
  })
}

export function getCmsArticles({query, cancelSignal}) {
  return get({
    url: '/najada2/cms-articles/',
    query,
    cancelSignal,
    config: {
      headers: {
        NoAuth: true
      }
    }
  })
}

export function getTags({query = {}, cancelSignal}) {
  return get({
    url: '/najada2/cms-tags/',
    query,
    cancelSignal,
    config: {
      headers: {
        NoAuth: true
      }
    }
  })
}

export function getTitles({query = {}, cancelSignal}) {
  return get({
    url: 'najada2/cms-titles/',
    query,
    cancelSignal,
    config: {
      headers: {
        NoAuth: true
      }
    }
  })
}
