import {captureMessage, captureException} from '@sentry/browser'
import { consola } from 'consola'
import isObject from 'lodash/isObject'
import ApiError from '~/structures/ApiError'
import { BaseError, NetworkError } from '~/structures/error/Error'

const NETWORK_ERROR = 'Network Error'
const REQUEST_ABORTED = 'ECONNABORTED'
const REQUEST_CANCELLED = 'ERR_CANCELED'

let isProduction = false

export function setProduction (value) {
  isProduction = value
}

export function getProduction () {
  return isProduction
}

export class LogService {
  /**
   *
   * @param {any} item
   * @param {string|null} section
   */
  static log (item, section = null) {
    if (!isProduction) {
      const items = section ? [section] : []
      items.push(item)

      consola.info(items.join('::'))
    }
  }

  /**
   *
   * @param {String} message
   */
  static warn (message) {
    if (isProduction) {
      captureMessage(message)
    } else {
      consola.warn(message)
    }
  }

  /**
   *
   * @param {Error} e
   * @param {any} value
   */
  static error (e, value = null) {
    if (e instanceof ApiError) {
      // Do not report unauthorized failed requests
      if (e.isCancelled
        || e.message === NETWORK_ERROR
        || e.statusCode === REQUEST_CANCELLED
        || e.statusCode === REQUEST_ABORTED
        || e.statusCode === 404
        || e.statusCode === 403
        || e.statusCode === 401
        || e.statusCode === 400
        || e.statusCode === 0
      ) return
    }

    let error = e

    if (e instanceof ApiError) {
      const networkError = new NetworkError()
      Object.assign(networkError, e)
      error = networkError
    } else if (!(e instanceof Error)) {
      error = new BaseError(e)
    }

    if(value) {
      if(error.value) {
        if(isObject(error.value)) {
          error.value = {
            ...error.value,
            value
          }
        }
      } else {
        error.value = value
      }
    }

    if (isProduction) {
      captureException(error)
    } else {
      consola.error(error)
    }
  }
}

export default LogService
